<template>
  <v-switch
    :input-value="hideResolvedToggle"
    class="py-0"
    inset
    hide-details
    @change="toggleResolvedComments({
      resourceType,
      resourceId,
      hideResolved: $event
    })">
    <template v-slot:label>
      <div
        v-if="!hideResolvedToggle"
        class="mr-1">
        <span class="font-weight-bold">Hide</span> resolved comments
      </div>
      <div v-else>
        Resolved comments are <span class="font-weight-bold">hidden</span>
      </div>
    </template>
  </v-switch>
</template>
<script>
import {
  mapActions,
  mapState,
} from 'vuex';
import CommentsResources from '@/mixins/CommentsResources';
import { COMMENTS_RESOURCES } from '@/constants/comments';
export default {
  name: 'AppCommentsToggleResolved',
  mixins: [CommentsResources],
  computed: {
    ...mapState('Comments', ['hideResolvedCollectionCommentsV2', 'hideResolvedProjectCommentsV2']),
    hideResolvedToggle() {
      return this[COMMENTS_RESOURCES[this.resourceType].hideResolvedToggle];
    },
  },
  methods: {
    ...mapActions({
      toggleResolvedComments: 'Comments/toggleResolvedCommentsV2',
    }),
  },
};
</script>
